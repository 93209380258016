<template>
  <div class="messages wrap">
    <div v-if="!data.results.length" class="d-flex align-center justify-center accent" style="height: 80vh">
      <div>
        <v-img height="143" contain src="@/assets/img/empty-msg.svg"></v-img>
        <div class="h8 mt-2 text--text text-center">No conversation yet</div>
      </div>
    </div>
    <div v-else class="d-flex">
      <v-card tile flat min-width="260" width="260" height="calc(100vh - 60px)" class="messages-list">
        <v-card tile flat height="60">
          <v-text-field
            class="pt-0 mt-0 students-list_search"
            v-model="search"
            @keyup="getData"
            @change="getData"
            placeholder="Search by name"
            prepend-inner-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            color="blue"
            solo
            hide-details
            clearable
            height="60"
            @click:clear="clearSearch"
          ></v-text-field>
          <v-divider class="hover"></v-divider>
        </v-card>
        <div
          v-for="item in data.results"
          :key="item.id"
          @contextmenu="showItem(item, $event)"
          @click="item.id == conversation.id ? '' : openItem(item.id)"
          class="d-flex align-start pa-4"
          :class="item.id == conversation.id ? 'active-user' : 'link'"
        >
          <v-avatar size="32" color="primary">
            <v-img v-if="item.student.picture" :src="item.student.picture"></v-img>
            <v-icon v-else color="white">mdi-account mdi-24px</v-icon>
          </v-avatar>
          <div class="ms-2 width100">
            <div class="d-flex justify-space-between">
              <div class="h7">{{ item.student.first_name }} {{ item.student.last_name }}</div>
              <div class="gray--text h12" v-if="!item.first_message_at">{{ pastTime(item.last_update_at) }}</div>
            </div>
            <div class="d-flex align-end justify-space-between">
              <div class="h10 text--text" v-if="item.last_message">
                {{ item.last_message.attachment ? item.last_message.attachment.title : item.last_message.text }}
              </div>
              <div class="count" v-if="item.unread_count">{{ item.unread_count }}</div>
            </div>
          </div>
        </div>
        <v-menu v-model="showMenuItem" offset-y absolute :position-x="x" :position-y="y">
          <v-list class="px-2 py-1">
            <div @click="modalChat = true" class="d-flex align-center link">
              <img class="me-1" src="@/assets/icon/delete.svg" />
              <div>Delete</div>
            </div>
          </v-list>
        </v-menu>
      </v-card>
      <div class="width100">
        <v-card tile flat height="60">
          <div v-if="infoBlock" class="text-center h7 text--text py-5">
            Info
            <v-btn style="position: absolute; left: 16px" @click="(infoBlock = false), bottomChat()" x-small text class="text--text px-1">
              <v-icon>mdi-chevron-left mdi-18px</v-icon> Back
            </v-btn>
          </div>
          <div v-else>
            <div style="height: 60px" class="d-flex align-center link ps-4" v-if="conversation.id" @click="openInfo(conversation.id)">
              <v-avatar size="42" color="primary">
                <v-img v-if="conversation.student.picture" :src="conversation.student.picture"></v-img>
                <v-icon v-else color="white">mdi-account mdi-36px</v-icon>
              </v-avatar>
              <div class="ms-2">
                <div class="h5">{{ conversation.student.first_name }} {{ conversation.student.last_name }}</div>
              </div>
            </div>
            <v-card v-else tile flat height="60"></v-card>
          </div>
          <v-divider class="hover"></v-divider>
        </v-card>
        <div v-if="infoBlock" class="overflow-y-auto px-sm-6 py-sm-4 pa-2 accent" style="height: calc(100vh - 120px)">
          <div class="text-center">
            <v-avatar size="90" color="primary">
              <v-img v-if="conversation.student.picture" :src="conversation.student.picture"></v-img>
              <v-icon style="font-size: 76px" v-else color="white">mdi-account</v-icon>
            </v-avatar>
          </div>
          <div class="h5 text-center">{{ conversation.student.first_name }} {{ conversation.student.last_name }}</div>
          <div class="mt-4 d-flex justify-center">
            <v-btn class="primary mx-2 px-0" height="54" min-width="54" @click="!conversation.block_status ? blockChat() : unlockChat()">
              <div class="text-center">
                <v-img height="24" width="24" class="mx-auto" contain src="@/assets/icon/lock-btn.svg"></v-img>
                <div class="h15 white--text mt-1">{{ !conversation.block_status ? 'Block' : 'Unblock' }}</div>
              </div>
            </v-btn>
            <v-btn class="primary mx-2 px-0" height="54" min-width="54" @click="muteChat">
              <div class="text-center">
                <v-img height="24" width="24" class="mx-auto" contain src="@/assets/icon/bell-btn.svg"></v-img>
                <div class="h15 white--text mt-1">Mute</div>
              </div>
            </v-btn>
            <v-btn class="primary mx-2 px-0" height="54" min-width="54" @click="clearChat">
              <div class="text-center">
                <v-img height="24" width="24" class="mx-auto" contain src="@/assets/icon/clear-btn.svg"></v-img>
                <div class="h15 white--text mt-1">Clear</div>
              </div>
            </v-btn>
            <v-btn class="primary mx-2 px-0" height="54" min-width="54" @click="openModalChat(conversation)">
              <div class="text-center">
                <v-img height="24" width="24" class="mx-auto" contain src="@/assets/icon/delete-white.svg"></v-img>
                <div class="h15 white--text mt-1">Delete</div>
              </div>
            </v-btn>
          </div>
          <div v-if="msgAtt.length">
            <v-divider class="hover my-5"></v-divider>
            <div class="h7 text--text">Attachments</div>
            <div v-for="item in msgAtt" :key="item.id" @click="scrollFix(item.id)" class="d-flex align-center mt-3 link">
              <v-img class="me-3" max-width="66" v-if="item.type == 'video'" :src="item.url"></v-img>
              <div v-else-if="item.type == 'pdf'" class="me-3">
                <v-img v-if="item.picture" max-width="66" :src="item.picture"></v-img>
                <v-card v-else tile flat width="66" height="50" color="#eaeafb" class="d-flex align-center justify-center">
                  <img class="d-block" src="@/assets/img/empty-file.svg" />
                </v-card>
              </div>
              <div v-else-if="item.type == 'link'" class="me-3">
                <v-img class="link d-flex align-end" v-if="item.url" height="54" cover :src="item.url"></v-img>
                <v-card v-else flat tile color="#eaeafb" width="66" height="54" class="d-flex align-center justify-center">
                  <img class="d-block" src="@/assets/img/empty-link.svg" />
                </v-card>
              </div>
              <v-img class="me-3" min-height="50" max-width="66" v-else :src="item.url"></v-img>
              <div>
                <div class="h7 clip">{{ item.title }}</div>
                <div class="h11 text--text clip2">{{ item.description }}</div>
                <div v-if="item.type == 'link'">
                  <a class="url--text h7" :href="item.link" target="_blank">{{ item.link }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="overflow-y-auto px-sm-6 py-sm-3 pa-2 accent" :style="`height: calc(100vh - 120px - ${heightBlock}px)`" id="window">
            <div v-for="item in list" :key="item.id" @contextmenu="show(item, $event)">
              <!-- <div class="text-center h10">
                <div class="gray--text" v-if="new Date(item.created_at).toDateString() == new Date().toDateString()">Today</div>
                <div class="gray--text" v-else>{{ new Date(item.created_at).toDateString() }}</div>
              </div> -->
              <div v-if="item.type == 'system'" class="h10 gray--text text-center">{{ item.text }}</div>
              <div
                v-else-if="item.deleted_at"
                class="mt-3 rounded-t-lg py-2 px-4 h8 font-italic"
                style="width: max-content; user-select: none"
                :class="item.created_by == conversation.tutor.id ? 'rounded-bl-lg white--text text ml-auto' : 'hover rounded-br-lg'"
              >
                Message was deleted
              </div>
              <div
                style="width: max-content"
                v-else-if="item.attachment"
                :id="item.attachment.id"
                class="mt-3"
                :class="item.created_by == conversation.tutor.id ? 'ml-auto' : ''"
              >
                <video
                  v-if="item.attachment.type == 'video'"
                  controls
                  class="msg-img rounded-t-xl"
                  :class="item.created_by == conversation.tutor.id ? 'rounded-bl-xl' : 'rounded-br-xl'"
                >
                  <source :src="item.attachment.url" />
                </video>
                <div v-else-if="item.attachment.type == 'pdf'" class="d-flex">
                  <img
                    class="d-block msg-img rounded-t-xl"
                    :class="item.created_by == conversation.tutor.id ? 'rounded-bl-xl' : 'rounded-br-xl'"
                    :src="item.attachment.preview_url"
                    alt="pdf"
                  />
                  <div class="h8">
                    <span>{{ item.attachment.title }}</span>
                    <a class="url--text" :href="item.attachment.url" target="_blank" rel="pdf">.pdf</a>
                  </div>
                </div>
                <img
                  v-else
                  class="d-block msg-img rounded-t-xl"
                  :class="item.created_by == conversation.tutor.id ? 'rounded-bl-xl' : 'rounded-br-xl'"
                  :src="item.attachment.url"
                  alt="image"
                />
              </div>
              <div
                v-else
                class="mt-3 rounded-t-lg py-2 px-4 h8"
                style="width: max-content; max-width: 55%"
                :class="item.created_by == conversation.tutor.id ? 'rounded-bl-lg white--text text ml-auto' : 'hover rounded-br-lg'"
              >
                {{ item.text }}
              </div>
              <div
                v-if="item.type != 'system'"
                class="h12 gray--text"
                :class="item.created_by == conversation.tutor.id ? 'ml-auto' : ''"
                style="width: max-content"
              >
                <span class="h12 pe-1">{{ new Date(item.created_at).toLocaleTimeString('en-GB').substring(0, 5) }}</span>
                <span class="h12" v-if="item.created_by == conversation.tutor.id">
                  <img
                    v-if="conversation.last_message_seen_at ? item.created_at <= conversation.last_message_seen_at : false"
                    height="12"
                    src="@/assets/icon/read.svg"
                  />
                  <img
                    v-else-if="conversation.last_message_delivered_at ? item.created_at <= conversation.last_message_delivered_at : false"
                    height="12"
                    src="@/assets/icon/delivered.svg"
                  />
                  <img v-else height="12" src="@/assets/icon/sent.svg" />
                </span>
              </div>
            </div>
            <v-menu v-model="showMenu" offset-y absolute :position-x="x" :position-y="y">
              <v-list class="px-2 py-1">
                <div @click="modalMsg = true" class="d-flex align-center link">
                  <img class="me-1" src="@/assets/icon/delete.svg" />
                  <div>Delete</div>
                </div>
              </v-list>
            </v-menu>
            <div v-if="!msgList.length" class="d-flex align-center justify-center" style="height: calc(100vh - 250px)">
              <div>
                <v-img height="143" contain src="@/assets/img/empty-msg.svg"></v-img>
                <div class="h8 mt-2 text--text text-center">No messages yet</div>
              </div>
            </div>
          </div>
          <v-card tile flat min-height="64" max-height="450" id="text-block">
            <v-divider class="hover"></v-divider>
            <v-card tile flat v-if="!conversation.block_status" class="d-flex align-center" min-height="63" max-height="449">
              <v-btn :disabled="!!msg" @click="changeMedia" depressed class="ms-4 hover px-0" height="24" width="24" icon>
                <v-icon color="gray">mdi-paperclip mdi-rotate-45 mdi-18px</v-icon>
              </v-btn>
              <v-textarea
                class="pt-0 mt-0 mb-1"
                v-model="msg"
                placeholder="Type your message here..."
                color="blue"
                solo
                no-resize
                :rows="row"
                hide-details
                @keyup.enter="rowTextarea"
                :disabled="!!file"
              ></v-textarea>
              <v-btn :disabled="!msg" @click="sendMsg" depressed class="me-4" height="28" width="100" color="primary">SEND</v-btn>
            </v-card>
            <div v-else-if="conversation.block_status == 'you_have_blocked'" class="text-center">
              <v-btn @click="unlockChat" text color="secondary" class="mt-3">
                <v-img height="24" width="24" class="mx-auto" contain src="@/assets/icon/unlock.svg"></v-img> Unblock
              </v-btn>
            </div>
            <div v-else class="text-center d-flex justify-center align-center" style="height: 62px">
              <v-img class="filter-gray" height="24" max-width="24" contain src="@/assets/icon/lock-btn.svg"></v-img>
              <div class="h7 gray--text">Blocked by student</div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <v-dialog v-model="modalChat" width="368">
      <v-card class="pa-6">
        <div class="h3 mb-4 text-center">
          Do you want to delete a chat with
          <span v-if="editedItem.student">{{ editedItem.student.first_name }} {{ editedItem.student.last_name }}</span>
        </div>
        <div class="text-right">
          <v-btn @click="modalChat = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="deleteChat(editedItem.id)" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalMsg" width="368">
      <v-card class="pa-6">
        <div class="h3 mb-4 text-center">Do you want to delete the message</div>
        <div class="text-right">
          <v-btn @click="modalMsg = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="deleteMsg(editedItem.id)" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      showMenuItem: false,
      showMenu: false,
      x: 0,
      y: 0,
      row: 1,
      heightBlock: 64,
      modalChat: false,
      modalMsg: false,
      infoBlock: false,
      search: this.$route.query.src || '',
      page: this.$route.query.page || 1,
      editedItem: {},
      list: [],
      msg: '',
      file: '',
      link: '',
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const data = new Object();
      data.search = this.search;
      data.page = this.page;
      await this.$store
        .dispatch('getMsgList', data)
        .then(() => {
          this.$route.query.dialog ? this.openItem(this.$route.query.dialog) : this.openItem(this.data.results[0].id);
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      }
    },
    clearSearch() {
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    pastTime(time) {
      if (Date.now() - time < 3600000) {
        return `${new Date(Date.now() - time).getMinutes()}m`;
      } else if ((Date.now() - time) / 3600000 < 24) {
        return `${Math.floor((Date.now() - time) / 3600000)}h`;
      } else {
        return `${Math.floor((Date.now() - time) / (3600000 * 24))}d`;
      }
    },
    async openItem(id) {
      this.infoBlock = false;
      await this.$store.dispatch('getMsgItem', id);
      await this.$store.dispatch('getMsgItemMsg', id).then(() => {
        window.history.pushState(
          {},
          '',
          `/messages?${this.search ? `src=${this.search}&` : ''}page=${this.page}&dialog=${this.conversation.id}`
        );
      });
    },
    async openInfo(id) {
      this.infoBlock = true;
      await this.$store.dispatch('getMsgAtt', id);
    },
    async sendMsg() {
      if (!this.file) {
        const data = new Object();
        data.text = this.msg;
        await this.$store.dispatch('getMsgItemMsgText', { id: this.conversation.id, text: data }).then((res) => {
          this.msg = '';
          this.msgList.push(res.data);
        });
      } else {
        const formData = new FormData();
        this.link ? (formData.append('link', this.link), formData.append('type', 'link')) : '';
        if (this.file) {
          this.file.type.substr(0, 5) == 'image' ? (formData.append('picture', this.file), formData.append('type', 'picture')) : '';
          this.file.type.substr(0, 5) == 'video' ? (formData.append('video', this.file), formData.append('type', 'video')) : '';
          this.file.type == 'application/pdf' ? (formData.append('pdf', this.file), formData.append('type', 'pdf')) : '';
        }
        await this.$store.dispatch('getMsgItemMsgAtt', { id: this.conversation.id, data: formData }).then((res) => {
          this.msg = '';
          this.file = '';
          this.msgList.push(res.data);
        });
      }
      this.$nextTick(() => {
        this.row = 1;
        this.heightBlock = 64;
      });
    },
    changeMedia() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      // input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function() {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = () => {
            self.msg = `${self.msg} ${self.file.name}`;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    bottomChat() {
      setTimeout(() => {
        const block = document.getElementById('window');
        block.scrollTo(0, block.scrollHeight || 0);
      }, 100);
    },
    async unlockChat() {
      await this.$store.dispatch('unlockMsgHistory', this.conversation.id);
    },
    async blockChat() {
      await this.$store.dispatch('blockMsgHistory', this.conversation.id);
    },
    async muteChat() {
      const data = new Object();
      data.notifications_enabled = !this.conversation.notifications_enabled;
      await this.$store.dispatch('updateMsgItem', { id: this.conversation.id, data: data });
    },
    async clearChat() {
      await this.$store.dispatch('clearMsgHistory', this.conversation.id);
    },
    async deleteChat(id) {
      await this.$store.dispatch('deleteMsgItem', id).then(() => {
        this.closeDialog();
        this.getData();
        this.$nextTick(() => {
          this.openItem(this.data.results[0].id);
        });
      });
    },
    async deleteMsg(id) {
      await this.$store.dispatch('deleteMsgItemMsg', { id: this.conversation.id, idMSg: id }).then(() => {
        this.$store.dispatch('getMsgItemMsg', this.conversation.id);
        this.closeDialog();
      });
    },
    show(item, e) {
      e.preventDefault();
      this.showMenu = false;
      this.showMenuItem = false;
      if (this.conversation.student.id != item.created_by && !item.deleted_at) {
        this.editedItem = Object.assign({}, item);
        this.x = e.clientX;
        this.y = e.clientY;
        this.$nextTick(() => {
          this.showMenu = true;
        });
      }
    },
    showItem(item, e) {
      e.preventDefault();
      this.showMenuItem = false;
      this.showMenu = false;
      this.editedItem = Object.assign({}, item);
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenuItem = true;
      });
    },
    closeDialog() {
      this.modalChat = false;
      this.modalMsg = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    scrollFix(id) {
      this.infoBlock = false;
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 100);
    },
    rowTextarea() {
      this.row < 3 ? this.row++ : '';
      this.$nextTick(() => {
        this.heightBlock = document.getElementById('text-block').clientHeight;
      });
    },
  },
  watch: {
    modalChat(val) {
      val || this.closeDialog();
    },
    modalMsg(val) {
      val || this.closeDialog();
    },
    msgList() {
      this.list = [];
      const arr = [];
      for (let i = 0; i < this.msgList.length; i++) {
        const e = this.msgList[i];
        arr.push({
          type: 'system',
          text:
            new Date(e.created_at).toDateString() == new Date().toDateString()
              ? 'Today'
              : new Date(e.created_at + 86400000).toDateString() == new Date().toDateString()
                ? 'Yesterday'
                : new Date(e.created_at).toDateString(),
        });
        arr.push(e);
      }
      this.list = Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse);
      this.bottomChat();
    },
  },
  computed: {
    data() {
      return this.$store.getters.msgList;
    },
    conversation() {
      return this.$store.getters.msgItem;
    },
    msgList() {
      return this.$store.getters.msgItemMsg;
    },
    msgAtt() {
      return this.$store.getters.msgAtt;
    },
  },
  destroyed() {
    this.$store.dispatch('setMsgList', { results: [] });
    this.$store.dispatch('setMsgItem', {});
    this.$store.dispatch('setMsgItemMsg', []);
    this.$store.dispatch('setMsgAtt', []);
  },
};
</script>

<style lang="scss">
.messages {
  &-list {
    border-right: 1px solid var(--v-hover-base) !important;
    overflow: auto;
  }
  .active-user {
    background: var(--v-hover-base);
    border-left: 2px solid var(--v-warning-base) !important;
    padding-left: 14px !important;
  }
  .count {
    width: 16px;
    text-align: center;
    color: white;
    background: var(--v-error-base);
    border-radius: 10px;
    font-size: 8px;
    line-height: 13px;
  }
  .msg-img {
    height: 200px;
  }
  .rounded-bl-lg {
    border-bottom-left-radius: 16px !important;
  }
  .rounded-br-lg {
    border-bottom-right-radius: 16px !important;
  }
  .rounded-t-lg {
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
  }
  .filter-gray {
    filter: invert(18%) brightness(95%) contrast(95%);
  }
  @media screen and (max-width: 599px) {
    .msg-img {
      height: 150px;
    }
  }
}
</style>
